// ============================================== Sticky CTA block  //
var setStickyCta = function() {
  var banner = document.querySelectorAll('.trip-banner-wrapper');

  if (banner.length === 0) return;

  banner = banner[0];

  var rect = banner.getBoundingClientRect();
  var offset = rect.bottom + window.scrollY;

  if (offset < 0) return;

  if (window.scrollY > offset) {
    document.body.classList.add('show-sticky-trip-cta-header');
    document.body.classList.remove('remove-sticky-trip-cta-header');
  }
  else {
    document.body.classList.add('remove-sticky-trip-cta-header');
    document.body.classList.remove('show-sticky-trip-cta-header');

    setTimeout(function() {
      document.body.classList.remove('remove-sticky-trip-cta-header');
    }, 500);
  }
}

// ============================================== Sticky CTA block 2 A/B testing //
var setStickyLandingCta = function() {
  var banner = document.querySelectorAll('.banner');

  if (banner.length === 0) return;

  banner = banner[0];

  var rect = banner.getBoundingClientRect();
  var offset = rect.bottom + window.scrollY;

  if (offset < 0) return;

  if (window.scrollY > offset) {
    document.body.classList.add('show-sticky-landing-cta-header');
    document.body.classList.remove('remove-sticky-landing-cta-header');
  }
  else {
    document.body.classList.add('remove-sticky-landing-cta-header');
    document.body.classList.remove('show-sticky-landing-cta-header');

    setTimeout(function() {
      document.body.classList.remove('remove-sticky-landing-cta-header');
    }, 500);
  }
}


window.onscroll = function(e) {
  setStickyLandingCta();
  setStickyCta(); // need to remove when A/B test is finished and add it above
  // scrollFunction() // back to top button
};
// ============================================== End A/B testing //

// button active
const mainNavLinks = document.querySelectorAll("a.ga-sticky-btn-anchor");
const mainNavLinks2 = document.querySelectorAll("a.tp-ga-sticky-btn-anchor");

function toggleLink(el, fromTop) {
  if (el.length === 0) return;

  el.forEach(link => {
    // check this is an actual element
    if (!Object.hasOwn(link, 'classList')) return;

    const section = document.querySelector(link.hash);

    if (section) {
      if (section.offsetTop <= fromTop
        && section.offsetTop + section.offsetHeight > fromTop
      ) {
        link.classList.add("active");
      }
      else {
        link.classList.remove("active");
      }
    }
  });
}

window.addEventListener("scroll", event => {
  const fromTop = window.scrollY + 120;

  toggleLink(mainNavLinks, fromTop);
  toggleLink(mainNavLinks2, fromTop);
});

// Back to top button
/*
var topbutton = document.getElementById("scrollBtn");
// When the user scrolls down 800px, show the button
function scrollFunction() {
  if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
  topbutton.style.display = "flex";
  } else {
  topbutton.style.display = "none";
  }
}
function topFunction() {
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;
}
*/


// ============================================== Mobile Navigation (only used for brilliant-africa and SA site)//
// console.log(window.environment.region); // delete this, but it'll spit out, e.g. 'patagonia'.
if(!['patagonia', 'antarctica', 'arctic'].includes(window.environment.region)) {
  var mobileNav = {
      init: function() {
        var navTarget  = document.querySelectorAll('.header')[0];
        var navParent  = document.querySelectorAll('.header .header-nav')[0];

        if (!navParent) return; // not on the page

        var mainNav    = document.querySelectorAll('.header .header-nav > .main-nav')[0];
        var menuTarget = document.querySelectorAll('.header .header-nav > .main-nav > ul')[0];
        var menuPos    = 0;
        var menuHeight = menuTarget ? menuTarget.offsetHeight : 0;

        menuTarget.classList.add('menu-pos-' + menuPos);

        // Menu open
        var btn = document.querySelectorAll('.mobile-expand button.icon-menu')[0];

        btn.addEventListener('touchend', function(e) {
          e.preventDefault();

          if (navTarget.classList.contains('mob-nav-open')) {
            // remove height from element
            navParent.style.maxHeight = 0;

            // Reset any sub navigations after closing menu
            setTimeout(function() {
              menuTarget.classList.remove('menu-pos-' + menuPos);
              menuPos = 0;
              menuTarget.classList.add('menu-pos-' + menuPos);
            }, 400);
          }
          else {
            // add height to element
            navParent.style.maxHeight = menuHeight;
          }

          navTarget.classList.toggle('mob-nav-open');
        }, false);

        // click submenu events
        var elems = document.querySelectorAll('.header .header-nav > .main-nav > ul a');

        for (var i = 0; i < elems.length; i++) {
          elems[i].addEventListener('touchend', function(e) {

            // move back
            if (e.target.classList.contains('icon-sub-back')) {
              e.preventDefault();

              // Set new height
              mainNav.style.height = menuHeight+'px';

              // move back
              menuTarget.classList.remove('menu-pos-' + menuPos);
              menuPos--;
              menuTarget.classList.add('menu-pos-' + menuPos);

              // find ul parent height
              if (menuPos != 0) {
                ulPars = 2;
                el = e.target;

                while (ulPars != 0) {
                  el = el.parentNode;
                  if (el.nodeName == 'UL') {
                    ulPars--;
                  }
                }

                if (ulPars == 0) {
                  mainNav.style.height = el.offsetHeight+'px';
                }
              }
            }

            // move forward
            if (e.target.classList.contains('icon-sub')) {
              e.preventDefault();

              // hide all other <ul>s
              var uls = document.querySelectorAll('.header .header-nav > .main-nav > ul ul');

              for (var j = 0; j < uls.length; j++) {
                uls[j].style.display = 'none';
              }

              var submenu = e.target.nextElementSibling;
              submenu.style.display = 'block';

              // set all parent <ul> to block
              var p = e.target.parentNode;

              while (!p.classList.contains('main-nav')) {
                  var o = p;

                  if (p.nodeName.toLowerCase() == 'ul') {
                    p.style.display = 'block';
                  }

                  p = o.parentNode;
              }

              // Set new height
              var subHeight = submenu.offsetHeight;
              mainNav.style.height = subHeight+'px';

              // move forwards
              menuTarget.classList.remove('menu-pos-' + menuPos);
              menuPos++;
              menuTarget.classList.add('menu-pos-' + menuPos);
            }
          });

        }
      }
  };
}
// <?php endif; ?>

// newsletter signup init
if (document.querySelectorAll('.js-newsletter-signup-trigger').length) {
  setNewsletterSignup();
}

setStickyCta();

// Cookie Warning Overlay
setCookieWarning.init();

// Initialize new Swiper carousel
window.addEventListener( 'load', function() {

  mobileNav?.init();

  var swiper = new Swiper('.date-picker.swiper-container', {
    slidesPerView: 1,
    simulateTouch: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    }
  });

  const getStandardGalleryConfig = (elCount) => {

    return {
        // preloadImages: false,
        lazy: true,
        watchSlidesVisibility: true,
        centeredSlides: true,
        spaceBetween: 30,
        // autoHeight: true,
        grabCursor: true,
        autoplay: {
          speed: 2000,
          delay: 2500,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: elCount == 1 ? false : true
    };
  }

  const getFullWidthGalleryConfig = (elCount) => {

    return {
      lazy: true,
      watchSlidesVisibility: true,
      centeredSlides: true,
      spaceBetween: 30,
      grabCursor: true,
      autoplay: {
        speed: 3000,
        delay: 2500,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      loop: elCount == 1 ? false : true
    };
  }

  const handleGalleryInit = (htmlContainerElement) => {

    // Swiper gallery config
    const elCount = htmlContainerElement.querySelectorAll('.swiper-slide').length;

    const gallerySwiperConfig = !htmlContainerElement.classList.contains('full-screen')
      ? getStandardGalleryConfig(elCount)
      : getFullWidthGalleryConfig(elCount);

    // IntersectionObserver is used to check if the gallery is in view
    const observer = new IntersectionObserver((entries) => {

      const entry = entries[0];

      const initSwiper = entry.isIntersecting && !htmlContainerElement.classList.contains('swiper-container-visible');

      if (initSwiper) {

        new Swiper(
          htmlContainerElement,
          gallerySwiperConfig
        );
        htmlContainerElement.classList.add('swiper-container-visible');
      }

    }, {
      threshold: .1
    });

    // the observer is added so that the swiper is only initialized when the gallery is in view
    const galleryWrapper = htmlContainerElement.closest(".gallery-wrapper");

    if (galleryWrapper !== null) {
      observer.observe(htmlContainerElement.closest(".gallery-wrapper"));
    }

  };

  document.querySelectorAll('.swiper-container.gallery').forEach(handleGalleryInit);

  var swiper = new Swiper('.swiper-container.swoop_team', {
    grabCursor: true,
    autoHeight: true,
  });

  document.querySelectorAll('.swiper-container.reviews').forEach(container => {
    // currently doesn't take into account loop scenario where slides per view < 3 slides
    var elCount = container.querySelectorAll('.swiper-slide').length;
    var swiperConf = {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      watchSlidesVisibility: true,
      slidesPerView: 1,
      spaceBetween: 10,
      freeMode: true,
      // loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        920: {
          slidesPerView: 2.5,
          spaceBetween: 32,
          slidesPerGroup: 2,
        },
      }
    };

    swiperConf.loop = elCount <= 2 ? false : true;
    var swiperTrip = new Swiper(container, swiperConf);

    container.style.display = 'block';
  });


  document.querySelectorAll('.swiper-container.quotes').forEach(container => {
    // currently doesn't take into account loop scenario where slides per view < 3 slides
    var elCount = container.querySelectorAll('.swiper-slide').length;
    var swiperConf = {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      grabCursor: true,
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    swiperConf.loop = elCount <= 2 ? false : true;

    var swiperTrip = new Swiper(container, swiperConf);

    container.style.display = 'block';
  });

  var tripCarousel = {
    // preloadImages: false,
    lazy: true,
    watchSlidesVisibility: true,
    loop: true,
    loopFillGroupWithBlank: true,
    grabCursor: true,
    centeredSlides: true,
    observer: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      639: {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      767: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
      1023: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
      1279: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    }
  };

  document.querySelectorAll('.swiper-container.trips').forEach(container => {
    // currently doesn't take into account loop scenario where slides per view < 3 slides
    var elCount = container.querySelectorAll('.swiper-slide').length;
    var swiperConf = tripCarousel;

    swiperConf.loop = elCount <= 2 ? false : true;

    var swiperTrip = new Swiper(container, swiperConf);

    container.style.display = 'block';

    var ctaMouseOverEvent = function(e) {
      swiperTrip.allowTouchMove = false;
    };
    var ctaMouseOutEvent = function(e) {
      swiperTrip.allowTouchMove = true;
    };

    // disable dragging on CTA buttons
    container.querySelectorAll('.js-swiper-cta').forEach(el => {
      el.addEventListener('mouseover', ctaMouseOverEvent);
      el.addEventListener('mouseout', ctaMouseOutEvent);
    });

  });

  var swiperRecommend = new Swiper('.swiper-container.you-may-like', tripCarousel);
  // PAT header message AB test - remove when PAT_info_carousel is over
  var swiper = new Swiper(".infoSwiper", {
      slidesPerView: 1,
      spaceBetween: 40,
      centeredSlides: true,
      loop: true,
      autoplay: {
      delay: 9500,
      disableOnInteraction: false,
      },
  });
});

var scrollToHash = function() {
var hash = window.location.hash.substring(1); // Puts hash in variable, and removes the # character
let hash_el = document.getElementById(hash);

if (hash_el) hash_el.scrollIntoView(true);
}

if (window.location.hash) {
if( document.readyState !== 'loading' ) {
  scrollToHash();
}
else {
  document.addEventListener('DOMContentLoaded', function () {
    scrollToHash();
  });
}
}


const snippet = document.querySelectorAll('.expert-banner');

if (snippet) {
const options = {
  threshold: 0,
  rootMargin: "-100px"
};

observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    }
    // console.log(entry.target);
    entry.target.classList.add("opacity-100");
    observer.unobserve(entry.target);
  });
}, options)

snippet.forEach(banner => {
  observer.observe(banner)
})
}

// ============================================== New Desktop Navigation - ued on PAT ANT ARC //

function menuSetup() {
let hideDelay = 400;
let menuEnterTimer, menuLeaveTimer;
let allMenuItems = document.querySelectorAll('#main-nav .menu-item');

for (let i = 0; i < allMenuItems.length; i++) {
  // triggered when user's mouse enters the menu item
  allMenuItems[i].addEventListener('mouseenter', function() {
    let thisItem = this;

    clearTimeout(menuLeaveTimer);

    for (let j = 0; j < allMenuItems.length; j++) {
      if (allMenuItems[j] == thisItem) continue;

      allMenuItems[j].classList.remove('active');
    }

    thisItem.classList.add('active');
  });
  // triggered when user's mouse leaves the menu item
  allMenuItems[i].addEventListener('mouseleave', function() {
    let thisItem = this;

    clearTimeout(menuEnterTimer);

    menuLeaveTimer = setTimeout(function() {
      thisItem.classList.remove('active');
    }, hideDelay);
  });
}
}

// dimmer show/hide

var nav = document.getElementById('main-nav');
var dimmer = document.getElementById('dimmer');
var clickedElements = [];

if (nav) {
  nav.addEventListener('mouseover', function(e) {
    dimmer.classList.remove("hidden");
  });

  nav.addEventListener('mouseleave', function() {
    setTimeout(function() {
      dimmer.classList.add("hidden");
    }, 400);
  });
}


function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
}

// document ready
ready(function() {
  menuSetup();
});